import * as React from "react";
import { Text, Heading, Link } from "@chakra-ui/react";

import { MainLayout } from "../layouts/Main";
import { ContentContainer } from "../components";

const AcknowledgmentsPage = () => {
  return (
    <MainLayout
      title="Acknowledgments"
      description="Acknowledgments and big thanks to the authors of icons for making Basilium possible."
      robots="noindex, follow"
    >
      <ContentContainer>
        <Heading as="h1">Acknowledgments</Heading>
        <Text my="6">
          Icons and images used in <Text as="strong">Basilium </Text> were
          designed by the following authors:
        </Text>
        {attributions.map((attribution) => {
          return (
            <Text>
              <Link
                href={attribution.url}
                target="_blank"
                color="brand.primary"
              >
                {attribution.author}
              </Link>{" "}
              from{" "}
              <Link href={FLATICON_URL} target="_blank">
                Flaticon
              </Link>
            </Text>
          );
        })}

        <Text mt="6">
          Big thanks to the authors for making <Text as="strong">Basilium</Text>{" "}
          possible.
        </Text>
      </ContentContainer>
    </MainLayout>
  );
};

export default AcknowledgmentsPage;

const attributions = [
  {
    author: "Smashicons",
    url: "https://www.flaticon.com/authors/smashicons",
  },
  {
    author: "Freepik",
    url: "https://www.flaticon.com/authors/freepik",
  },
  {
    author: "DinosoftLabs",
    url: "https://www.flaticon.com/authors/dinosoftlabs",
  },
  {
    author: "Pixel perfect",
    url: "https://www.flaticon.com/authors/pixel-perfect",
  },
  {
    author: "Vectors Market",
    url: "https://www.flaticon.com/authors/vectors-market",
  },
  {
    author: "Nikita Golubev",
    url: "https://www.flaticon.com/authors/nikita-golubev",
  },
  {
    author: "monkik",
    url: "https://www.flaticon.com/authors/monkik",
  },
  {
    author: "turkkub",
    url: "https://www.flaticon.com/authors/turkkub",
  },
  {
    author: "Vitaly Gorbachev",
    url: "https://www.flaticon.com/authors/vitaly-gorbachev",
  },
  {
    author: "Good Ware",
    url: "https://www.flaticon.com/authors/good-ware",
  },
  {
    author: "ultimatearm",
    url: "https://www.flaticon.com/authors/ultimatearm",
  },
  {
    author: "surang",
    url: "https://www.flaticon.com/authors/surang",
  },
  {
    author: "photo3idea_studio",
    url: "https://www.flaticon.com/authors/photo3idea-studio",
  },

  {
    author: "Elias Bikbulatov",
    url: "https://www.flaticon.com/authors/elias-bikbulatov",
  },
  {
    author: "Cursor Creative",
    url: "https://www.flaticon.com/authors/cursor-creative",
  },
  {
    author: "Those Icons",
    url: "https://www.flaticon.com/authors/those-icons",
  },
  {
    author: "Darius Dan",
    url: "https://www.flaticon.com/authors/darius-dan",
  },
  {
    author: "srip",
    url: "https://www.flaticon.com/authors/srip",
  },
  {
    author: "Becris",
    url: "https://www.flaticon.com/authors/becris",
  },
  {
    author: "xnimrodx",
    url: "https://www.flaticon.com/authors/xnimrodx",
  },
  {
    author: "itim2101",
    url: "https://www.flaticon.com/authors/itim2101",
  },
  {
    author: "Icongeek26",
    url: "https://www.flaticon.com/authors/icongeek26",
  },
];

const FLATICON_URL = "https://www.flaticon.com/";
